"use client";

import AutoForm, { AutoFormButton } from "@/components/ui/auto-form";
import { toast } from "@/components/ui/use-toast";
import { useAction } from "next-safe-action/hooks";
import { useEffect } from "react";
import { signIn } from "../../action";
import { UserAuthSchema } from "../../schema";

export function UserLoginForm() {
  const formAction = useAction(signIn, {
    onError: ({ error }) => {
      toast({
        title: "Sign-in failed",
        description:
          error.validationErrors?._errors?.[0] ??
          "An error occurred during sign-in.",
        variant: "destructive"
      });
    }
  });

  useEffect(() => {
    if (
      Notification.permission !== "granted" &&
      Notification.permission !== "denied"
    ) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.info("Notification permission granted.");
        }
      });
    }
  }, []);

  return (
    <AutoForm
      formSchema={UserAuthSchema}
      formAction={formAction}
      fieldConfig={{
        email: {
          inputProps: {
            placeholder: "you@watt.com",
            autoCapitalize: "none",
            autoComplete: "email",
            autoCorrect: "off"
          }
        },
        password: {
          inputProps: {
            type: "password",
            placeholder: "••••••••",
            autoComplete: "current-password",
            autoCapitalize: "none",
            autoCorrect: "off"
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <AutoFormButton
          disabled={isSubmitting}
          isSubmitting={isSubmitting}
          className="w-full"
        >
          Login
        </AutoFormButton>
      )}
    </AutoForm>
  );
}
